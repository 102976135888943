header {
  flex-shrink: 0;
}
.nav-top {
  font-family: font-family(korolev);
  font-weight: font-weight(korolev-medium);
  background-color: color(dark-blue);
  max-height: 90px;
  padding: em(19) 0;
  @media only screen and (max-width: 899px) {
    display: none;
  }
  .container {
    @include respond-to(medium-large) {
      display: flex;
      box-sizing: border-box;
    }
  }
  .dropdown-container {
    @include respond-to(medium-large) {
      overflow: visible;
    }
  }
  .menu-user {
    display: none;
    @include respond-to(medium-large) {
      display: inline-flex;
      align-items: center;
      margin-left: auto;
    }
  }
  .user-info {
    margin-right: em(20);
    @include respond-to(medium) {
      margin-right: em(10);
    }
    @include respond-to(large) {
      margin-right: em(20);
    }
  }
  .user-account {
    font-size: em(14);
    @include svg-sprite(account-icon);
    margin-right: em(6);
    span {
      display: none;
    }
  }
  .mobile-hello {
    display: none;
  }
  .desktop-hello {
    display: none;
    @include respond-to(large) {
      display: block;
    }
  }
}
.nav-top-empty {
  .container {
    @include respond-to(medium-large) {
      height: em(57);
    }
  }
}
.nav-main {
  position: relative;
  background-color: color(light-blue);
  vertical-align: middle;
  background: color(black);
  @include respond-to(medium-large) {
    background: color(white);
    padding: 0;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include respond-to(medium-large) {
      height: em(60);
      padding: 0 rem(15);
    }
  }
  .user-info {
    @include respond-to(medium-large) {
      display: none;
    }
  }
  .mini-cart {
    @include respond-to(medium-large) {
      display: none;
    }
  }
  .mini-cart-link {
    vertical-align: middle;
  }
  .icon-menu {
    @include respond-to(medium-large) {
      display: none;
    }
  }
  .menu-user {
    display: flex;
    align-items: center;
    @include respond-to(medium-large) {
      display: none;
    }
  }
  .user-account {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-right: em(8);
    &:hover {
      color: color(light-blue);
    }
    &:after {
      @include svg-sprite(arrow-right);
      font-size: 6px;
      content: '';
      margin-left: rem(10);
    }
  }
  .desktop-hello {
    display: none;
  }
  .mobile-hello {
    font-size: em(12);
    color: color(gray);
    line-height: 1.2em;
    text-transform: uppercase;
    padding: 0 rem(20);
    margin: 0 0 em(5) 0;
  }
}
.nav-brands {
  text-align: center;
  box-sizing: border-box;
  @include respond-to(medium-large) {
    display: inline-flex;
    align-items: center;
    text-align: left;
  }
  .dropdown {
    background-color: color(dark-blue);
    @include respond-to(medium-large) {
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      width: auto;
      height: auto;
      max-height: none;
    }
  }
  .nav-brands-current {
    @include respond-to(medium-large) {
      position: relative;
      display: inline-block;
      color: color(light-blue);
      text-align: center;
    }
  }
  a {
    display: block;
    font-size: em(21);
    color: color(white);
    text-transform: uppercase;
    @include respond-to(medium-large) {
      display: inline-block;
      font-size: font-size(h4);
      &:hover {
        color: color(light-blue);
      }
    }
  }
  .cat-focus {
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    @media only screen and (min-width: 1024px) {
      background-size: 75px;
    }
    &:hover {
      img {
        opacity: 0;
      }
    }
  }
}
.logo {
  display: none;
  width: em(200);
  padding: em(5) 0;
  @include respond-to(medium-large) {
    display: inline-block;
  }
  img {
    vertical-align: middle;
  }
}

.logo-mobile.logo-authenticated {
  display: block;
  margin: 0 auto;

  @include respond-to(medium-large) {
    display: none;
  }

  img {
    height: 40px;
    width: auto;
    padding-top: em(10);
    padding-right: 40px;
    padding-bottom: em(10);

    @media only screen and (min-width: 768px) {
      height: 50px;
    }
  }
}
.user-links {
  display: none;
}
.menu-category-links {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateX(-100%);
  transition: transform transition(dropdown);
  background-color: color(extra-light-gray);
  box-sizing: border-box;
  width: 90%;
  height: 100vh;
  padding: em(20) 0;
  @include respond-to(medium-large) {
    position: static;
    transform: none;
    background-color: transparent;
    transition: none;
    width: auto;
    height: auto;
    padding: 0;
  }
  a {
    display: block;
    font-family: font-family(korolev);
    font-size: em(22);
    font-weight: font-weight(korolev-light);
    letter-spacing: 0.035em;
    line-height: em(45);
    text-transform: uppercase;
    padding: 0 rem(20);
    @include respond-to(medium-large) {
      display: inline-block;
      color: color(white);
      line-height: 1em;
      padding: 0 em(8);
      &:hover,
      &.selected {
        color: color(dark-blue);
      }
    }
  }
}
.menu-active {
  overflow-x: hidden;
  .menu-category-links {
    transform: translateX(0);
  }
  main, #main {
    transform: translateX(90%);
    box-shadow: -5px 0px 20px -3px rgba(0,0,0,0.15);
    overflow: hidden;
    height: 100vh;
  }
  footer {
    overflow: hidden;
    width: 100vw;
  }
}
.nav-brands-trigger {
  display: none;
  background-color: transparent;
  padding: em(20) em(5);
  margin: 0;
  &:hover {
    background-color: transparent;
    span {
      @include svg-sprite(ellipse-icon-hover, block);
    }
  }
  &:after {
    display: none;
  }
  @include respond-to(medium-large) {
    display: block;
    text-align: center;
  }
  span {
    @include svg-sprite(ellipse-icon, block);
  }
}
.dropdown-sub {
  position: relative;
  text-align: center;
  padding: 0 em(5);
  margin: 0 0 0 em(5);
  &.dropdown-active {
    .dropdown {
      @include respond-to(medium-large) {
        max-height: 100vh;
      }
    }
  }
  @include respond-to(medium-large) {
    display: inline-block;
  }
  .dropdown {
    @include respond-to(medium-large) {
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      transition: max-height transition(dropdown);
      overflow: hidden;
      background-color: color(dark-blue);
      max-height: 0;
    }
  }
  a {
    display: block;
    line-height: 1.2em;
    padding: em(5) 0;
    margin: 0 0 em(10) 0;
    @include respond-to(medium-large) {
      padding: 0 em(10);
      &:first-child {
        margin-top: em(15);
      }
      &:last-child {
        margin-bottom: em(15);
      }
    }
  }
}
.menu-user {
  .user-account-wrapper {
    display: inline-block;
    @include respond-to(medium-large) {
      position: relative;
    }
  }
  .user-links {
    position: absolute;
    top: 98%;
    left: 0;
    z-index: zindex(dropdown);
    font-family: font-family(lato);
    font-weight: font-weight(lato-regular);
    font-size: em(14);
    text-align: right;
    background-color: color(light-blue);
    box-sizing: border-box;
    width: 100%;
    padding: 0 em(10) em(10);
    @include respond-to(medium-large) {
      text-align: left;
      white-space: nowrap;
      background-color: color(dark-blue);
      top: calc(100% + 10px);
      width: auto;
      min-height: em(68);
      padding: em(5) em(10) em(10) em(10);
    }
  }
  li {
    margin: 0 0 em(12) 0;
    &:last-child {
      margin: 0;
    }
  }
  a {
    color: color(white);
    &:hover {
      color: color(light-blue);
    }
  }
}
.user-info {
  border-bottom: 1px solid darken(color(extra-light-gray), 15%);
  &.active {
    .user-account {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
  b {
    display: block;
  }
  @include respond-to(medium-large) {
    display: flex;
    align-items: center;
    color: color(white);
    line-height: 1em;
    border: none;
  }
}
