body {
  font-family: font-family(lato), font-family(sans-serif);
  font-size: font-size(base);
  line-height: em(30px);
}
%h1 {
  font-family: font-family(korolev);
  font-size: font-size(h1-mobile);
  font-weight: font-weight(korolev-bold);
  color: color(dark-blue);
  text-align: center;
  line-height: 1.1em;
  margin: 0 0 rem(20) 0;
  @include respond-to(large) {
    font-size: font-size(h1);
  }
}
h1 {
  @extend %h1;
  i {
    display: block;
    font-family: font-family(lato);
    font-size: font-size(h2-mobile, true);
    font-weight: font-weight(lato-light);
    font-style: italic;
    color: color(black);
    line-height: 1em;
    margin: 0;
    @include respond-to(large) {
      font-size: font-size(h2, true);
    }
  }
}
%h2 {
  font-family: font-family(lato);
  font-size: font-size(h2-mobile);
  font-weight: font-weight(lato-regular);
  line-height: 1.2em;
  margin: 0 0 em(15) 0;
  @include respond-to(large) {
    font-size: font-size(h2);
  }
}
h2 {
  @extend %h2;
  .regular {
    display: block;
    font-weight: font-weight(lato-light);
    @include respond-to(medium) {
      display: inline-block;
      border-left: 2px solid color(black);
      padding-left: rem(20);
      margin-left: rem(20);
    }
  }
}
%h3 {
  font-family: font-family(korolev);
  font-size: font-size(h3);
  font-weight: font-weight(korolev-bold);
  color: color(dark-blue);
  line-height: 1.2em;
  margin: 0 0 em(15) 0;
}
h3 {
  @extend %h3;
}
%h4 {
  font-family: font-family(lato);
  font-size: font-size(h4);
  font-weight: font-weight(lato-heavy);
  line-height: 1.2em;
  margin: 0 0 em(10) 0;
}
h4 {
  @extend %h4;
}
%p {
  font-family: font-family(lato);
  font-size: font-size(p);
  font-weight: font-weight(lato-regular);
  margin: 0 0 em(15) 0;
}
p {
  @extend %p;
  &:last-of-type {
    margin: 0;
  }
}
a {
  color: color(light-blue);
  text-decoration: none;
  &:hover {
  color: color(light-blue-hover);
  }
}
b,
strong {
  font-weight: font-weight(lato-heavy);
}
.red {
  color: color(red);
}
.on-order {
  color: color(orange);
}
.disclaimer {
  font-size: em(12);
  font-style: italic;
  color: color(gray);
  line-height: 1.4em;
}
.section-header-note {
  font-size: font-size(label);
  font-weight: font-weight(lato-regular);
  line-height: 1em;
}
%refinement-header {
  display: block;
  font-family: font-family(lato);
  font-weight: font-weight(lato-heavy);
  font-size: font-size(h4);
  color: color(dark-blue);
  cursor: pointer;
  background-color: color(extra-light-gray);
  padding: em(10);
  margin: 0;
}
%refinement-container {
  border: 1px solid color(extra-light-gray);
  margin: 0 0 em(30) 0;
}

@include typekit-foit();
