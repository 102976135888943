.breadcrumb {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumb-element {
  display: inline-block;
  font-family: font-family(korolev);
  font-size: em(16);
  font-weight: font-weight(korolev-medium);
  &:after {
    display: inline-block;
    content: '•';
    color: color(medium-light-gray);
    padding: 0 em(14);
  }
  &:last-child {
    margin: 0;
    &:after {
      display: none;
    }
  }
}
