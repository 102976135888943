.dropdown {
  transition: max-height transition(dropdown);
  z-index: zindex(dropdown);
  width: 100%;
  max-height: 0;
}
.dropdown-container {
  overflow: hidden;
}
.nav-brands-current {
  &:after {
    content: '';
    font-size: em(14);
    vertical-align: middle;
    @include svg-sprite(arrow-icon);
    margin-left: em(5);
    margin-right: em(-10);
    @include respond-to(medium-large) {
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-47%);
      margin: 0;
      @include svg-sprite(arrow-header);
    }
  }
  &.expanded {
    &:after {
      @include svg-sprite(arrow-icon-select);
      @include respond-to(medium-large) {
        @include svg-sprite(arrow-header);
      }
    }
  }
}
.dropdown-active {
  .nav-brands-current {
    &:after {
      transform: scaleY(-1);
      @include respond-to(medium-large) {
        transform: 0;
      }
    }
  }
  .dropdown {
    overflow-y: scroll;
    max-height: 100vh;
    @include respond-to(large) {
      overflow: auto;
    }
  }
}
