%checkout-header {
  display: flex;
  align-items: baseline;
  font-family: font-family(lato);
  font-size: font-size(h4);
  font-weight: font-weight(lato-heavy);
  color: color(white);
  line-height: 1em;
  background-color: color(dark-blue);
  box-sizing: border-box;
  width: 100%;
  padding: em(18) em(20);
  margin: 0 0 em(20) 0;
}

.main-checkout {
  padding-bottom: em(90);
  @include respond-to(medium) {
    padding-bottom: 0;
  }
}

.container-checkout {
  position: relative;
  @include respond-to(medium) {
    position: static;
  }
}

.checkout-primary {
  @include respond-to(medium) {
    margin: 0 0 em(30);
  }
  form {
    width: 100%;
    margin: 0;
  }
  legend {
    @extend %checkout-header;
    @include respond-to(large) {
      width: 100%;
    }
  }
  .fieldset {
    width: 100%;
    margin: 0 0 em(50) 0;
    &:last-of-type {
      margin: 0;
    }
    @include respond-to(large) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &.fieldset-shipping-employee {
      display: none;
    }
  }
  .fieldset-payment {
    @include respond-to(large) {
      display: block;
    }
    .form-row {
      @include respond-to(large) {
        padding-right: 52%;
      }
    }
  }
  .form-row {
    margin: 0 0 em(15) 0;
    @include respond-to(large) {
      width: 48%;
    }
  }
  .form-row-checkbox {
    @include respond-to(large) {
      width: 100%;
      margin: 0 0 em(5) 0;
    }
  }
  .form-row-button {
    position: absolute;
    top: calc(20px + 100%);
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0 em(20);
    @include respond-to(medium) {
      position: static;
      top: auto;
      left: auto;
      text-align: right;
      padding: 0;
      margin: 0 0 em(5) 0;
    }
  }
  .select-address,
  .form-row-half-full {
    @include respond-to(large) {
      width: 48%;
      padding-right: 52%;
    }
    .form-row {
      width: 100%;
      margin: 0;
    }
  }
  .payment-method-options {
    display: none;
  }
  .input-group {
    .form-row {
      width: 48%;
      @include respond-to(medium) {
        width: 48%;
        padding: 0;
      }
    }
  }
  .cvn {
    input[type="text"]  {
      width: 48%;
      @include respond-to(medium) {
        width: 30%;
      }
    }
  }
  .payment-method {
    display: none;
  }
  .payment-method-expanded {
    display: block;
  }
}

.checkout-secondary {
  .section-header {
    @extend %checkout-header;
  }
  .section-header-note {
    margin-left: auto;
  }
  .checkout-mini-cart {
    margin: 0 0 em(20);
  }
}

.checkout-progress-indicator {
  display: flex;
  font-weight: font-weight(lato-heavy);
  font-size: font-size(h4);
  color: color(medium-light-gray);
  margin: 0 0 em(30);
  @include respond-to(large) {
    width: 100%;
  }
  div {
    display: flex;
    width: 33.33%;
    &:after {
      display: inline-block;
      font-size: em(12);
      font-weight: font-weight(lato-regular);
      color: color(medium-light-gray);
      content: '•';
      margin: 0 auto;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  .active {
    color: color(black);
  }
  span {
    display: none;
    &:first-child {
      display: inline;
    }
    @include respond-to(large) {
      display: inline;
      &:first-child {
        margin-right: em(10);
      }
    }
  }
}

.order-totals-table {
  font-size: font-size(label);
  line-height: 1.2em;
  background-color: color(extra-light-gray);
  box-sizing: border-box;
  padding: em(30) em(20);
}

.cart-order-totals,
.checkout-order-totals {
  dt,
  dd {
    margin: 0 0 em(7) 0;
  }
  .order-shipping {
    a {
      display: block;
    }
  }
  .order-subtotal,
  .order-total {
    font-weight: font-weight(lato-heavy);
  }
  .vat {
    font-size: em(11);
    color: color(gray);
    text-align: right;
    flex-basis: 100%;
    margin: 0;
  }
}

.checkout-order-totals {
  margin: 0 0 em(30) 0;
}

.place-order-totals {
  .order-totals-table  {
    background-color: transparent;
    padding: 0 em(20);
    margin: 0 0 em(20) 0;
  }
}

.checkout-shipping,
.checkout-billing {
  button {
    @extend %button-submit;
  }
}

#shipping-method-list {
  width: 100%;
  .form-row {
    position: relative;
  }
  .field-wrapper {
    text-align: center;
    width: 8%;
    margin: 0;
  }
  .form-caption {
    display: block;
    width: 100%;
    padding-left: 8%;
  }
  label {
    span {
      display: block;
      @include respond-to(large) {
        display: inline-block;
      }
    }
    .tooltip {
      display: block;
    }
  }
  .tooltip {
    margin: 0;
  }
}

.order-component-block {
  font-size: font-size(label);
  font-weight: font-weight(lato-regular);
  line-height: 1.4em;
  margin: 0 0 em(20) 0;
  .section-header {
    justify-content: center;
    font-size: font-size(label);
    line-height: 1.4em;
    color: color(black);
    background-color: transparent;
    padding: 0;
    margin: 0 0 em(10) 0;
  }
  .section-header-note {
    line-height: 1.4em;
    order: 2;
  }
  .address {
    margin: 0 0 em(5) 0;
  }
  .minishipments-method {
    display: inline-block;
    margin: 0 em(5) 0 0;
  }
}

.order-summary-footer {
  .form-row {
    @include respond-to(medium) {
      text-align: right;
      width: auto;
    }
  }
  .back-to-cart {
    display: block;
    text-align: right;
    margin: 0 0 em(50) 0;
  }
}

.primary-order-summary {
  .confirmation-message {
    margin: 0 0 em(30) 0;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

.order-confirmation-error {
  color: color(red);
  margin: 0 0 em(20) 0;
}
