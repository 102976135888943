.container-account {
  form {
    margin: 0;
    &[role="search"] {
      box-sizing: border-box;
      width: 100%;
    }
  }
  h1,
  .section-header {
    text-align: left;
    @extend %h3;

  }
  .section-header-note {
    @extend %p;
    display: block;
    font-size: font-size(p, true);
    @include respond-to(medium) {
      display: inline-block;
      margin-left: em(10);
    }
  }
  h3 {
    @extend %h4;
    text-transform: uppercase;
  }
  legend {
    @extend %h4;
  }
  fieldset {
    margin: 0 0 em(40) 0;
  }
  label {
    font-weight: font-weight(lato-regular);
    font-size: font-size(label);
    span {
      margin-right: em(3);
    }
  }
  .label-inline {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  button[type="submit"] {
    @extend %button;
    @extend %button-submit;
  }
  .button-text[type="submit"] {
    @extend %button-text;
    margin: 0 0 0 auto;

  }
  .button-delete[type="submit"] {
    @extend %button-text;
    margin: 0;
  }
}

.input-text {
  max-width: 450px;
}

.login-primary {
  display: flex;
  flex-direction: column;
  
  @include respond-to(medium-large) {
    flex-direction: row;
  }

  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: space-between;
  order: 1;
  flex-grow: 2;
  flex: 1;

  & > div form {
    width: 100%;
    padding-bottom: em(30);
    @include respond-to(medium) {
      width: 70%;
      padding-bottom: em(10);
      margin-bottom: em(20);
    }
  }

  form,
  .dropdown-container {
    width: 100%;
    padding-bottom: em(30);
    @include respond-to(medium) {
      width: 40%;
      padding-bottom: em(10);
      margin-bottom: em(20);
    }
  }
  .dropdown-container {
    form {
      width: 100%;
    }
  }
  .dropdown {
    max-height: none;
  }
  h2 {
    @extend %h3;
  }
  .form-row {
    width: 100%;
  }
  .form-row-submit {
    margin-top: 0;
  }
  .error-form {
    color: #e9242d;
  }
  form[name="NewPasswordForm"] {
    button {
      @extend %button-secondary ;
    }
  }
  form[name="PasswordResetForm"],  form[name="NewPasswordForm"]  {
    width: 100%;
    .fieldset, fieldset {
      max-width: 100%;
      margin: 0 auto;
      @include respond-to(medium-large) {
        max-width: 500px;
      }
    }
  }
}
.registration-primary {
  margin: 0 auto;
}
.login-secondary {
  flex-basis: 100%;
  order: 2;
  .need-help {
    max-width: none;
  }
}
.account-login-quicklinks {
  display: flex;
  justify-content: center;
  li {
    line-height: 1em;
    &:first-child {
      border-right: 1px solid color(black);
      padding-right: em(20);
      margin-right: em(20);
    }
  }
}
.account-modal-form {
  .input-group {
    justify-content: flex-start;
  }
}
.address-list,
.payment-list {
  li {
    font-size: font-size(label);
    line-height: em(26);
    margin: 0 0 em(20) 0;
    @include respond-to(large) {
      margin: 0 0 em(40) 0;
    }
  }
  .default {
    border-bottom: 2px solid color(extra-light-gray);
  }
  .mini-address-title {
    font-weight: font-weight(lato-heavy);
  }
}
.address-list {
  li {
    padding: 0 0 em(20) 0;
    @include respond-to(large) {
      padding: 0 0 em(40) 0;
    }
  }
}
.manage-codes-wrapper {
  margin: 0 0 em(30);

  .fieldset {
    align-items: flex-end;
  }

  .form-row {
    margin: 0;
  }

  .manage-codes-success {
    border: 2px solid #00a651;
    margin-bottom: 15px;
    position: relative;

    .close-manage-codes-success {
      height: 43px;
      width: 43px;
      right: 15px;
      top: 12px;
      position: absolute;
      color: #fff;
      padding: 0;
      margin: 0;
      text-align: right;
      font-size: 8px;
      @include svg-sprite(close-icon-white);
    }

    h2 {
      background-color: #00a651;
      width: 100%;
      color: #ffffff;
      font-family: font-family(lato), font-family(sans-serif);
      font-weight: font-weight(lato-heavy);
      font-size: 18px;
      line-height: 43px;
      padding: 0 15px;
      text-transform: uppercase;
      box-sizing: border-box;
      margin: 0;
      position: relative;
    }

    p {
      font-size: 15px;
      padding: 15px;
      line-height: 18px;
    }

  }


  .error-form .input-text {
    border: 2px solid #e9242d;
  }


  .error-form {
    .input-text {
      border: 2px solid #e9242d;
    }

    .form-row {
      margin: 0;
    }

    .error-message {
      background: #e9242d;
      color: color(white);
      font-weight: font-weight(lato-heavy);
      padding: 0 em(10);
      font-size: 15px;
      margin: 0 0 16px 0;
      width: 62.5%;
      box-sizing: border-box;
    }
  }

  form {
    max-width: 560px;
    width: 100%;
    overflow: hidden;
    margin: 0;

    .error-form .input-text,
    .input-text {
      &.error {
        border: 2px solid #e9242d;
      }
    }

    span.error {
      background: #e9242d;
      color: color(white);
      font-weight: font-weight(lato-heavy);
      padding: 0 em(10);
      font-size: 15px;
    }

    input[type="text"] {
      font-size: 15px;
    }

    .update-code-input {
      width: 100%;
      margin: 0 0 em(10);
      display: inline-block;
      float: left;

      @include respond-to(small) {
        width: 62.5%; /* 350 / 560 */
        margin: 0 1.7857142857142856% 0 0;
      }
      @include respond-to(medium) {
        margin: 0;
      }
    }

    .update-code-button {
      width: 100%;
      display: inline-block;


      @include respond-to(small) {
        width: 35.714285714285715%; /* 200 / 560 */
      }

      button {
        width: 100%;
        margin: 0;
        font-size: 18px;
        font-weight: font-weight(lato-heavy);
        font-family: font-family(lato);
        line-height: 40px;
        padding: 0;
        text-transform: uppercase;
      }
    }

  }

  h2 {
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    color: color(dark-blue);
    font-size: 30px;
    text-transform: none;
  }

  h3 {
    font-size: 18px;
    font-weight: font-weight(lato-heavy);
    font-family: font-family(lato);
    text-transform: uppercase;
  }

  .current-active-code {
    font-size: 18px;
    font-family: font-family(lato);
    padding-bottom: 36px;
    border-bottom: 1px solid color(extra-light-gray);
    margin-bottom: 20px;
  }

  .form-row {
    label {
      span {
        font-size: 18px;
        font-weight: font-weight(lato-heavy);
        font-family: font-family(lato);
      }

      .required-indicator {
        display: none;
      }
    }
  }

  .account-newcode-disclaimer {
    font-size: 14px;
    font-family: font-family(lato);
    line-height: 18px;
    margin-top: 10px;

    @include respond-to(small) {
      margin-top: 0;
    }
  }
}

.account-options {
  li {
    background-color: color(extra-light-gray);
    box-sizing: border-box;
    padding: em(20);
  }
  .account-title-bg {
    color: color(black);
    font-weight: font-weight(lato-heavy);
  }
  p {
    margin: 0 0 em(20) 0;
  }
}
.account-nav {
  margin: 0 0 em(40) 0;
  .content-asset {
    .toggle {
      @extend %refinement-header;
    }
    ul {
      @extend %refinement-container;
      padding: em(20);
    }
    li {
      line-height: 1.2em;
      margin: 0 0 em(15) 0;
    }
  }
}
.modal-contact {
  ul {
    margin: 0;
  }
}
#PasswordResetForm {
  button[type="submit"] {
    @extend %button;
    @extend %button-submit;
  }
}
.container-order-history {
  .results-hits {
    font-weight: font-weight(lato-regular);
  }
}
.order-history-header {
  font-family: font-family(lato);
  font-weight: font-weight(lato-regular);
  font-size: font-size(p);
  text-transform: uppercase;
  line-height: 1em;
  background-color: color(extra-light-gray);
  padding: em(14) em(15);
  margin: 0 0 em(30) 0;
}
.order-history-list {
  line-height: 1.2em;
  border-bottom: 2px solid color(extra-light-gray);
  padding: 0 0 em(25) 0;
  margin: 0 0 em(30) 0;
  h4 {
    font-weight: font-weight(lato-regular);
    font-size: font-size(p);
    text-transform: uppercase;
    margin: 0 0 em(10) 0;
  }
  .order-info {
    margin: 0 0 em(20) 0;
  }
  .order-items {
    margin: 0 0 em(20) 0;
  }
  .order-product {
    margin: 0 0 em(10) 0;
  }
  .order-product-brand {
    font-weight: font-weight(lato-heavy);
    color: color(gray);
  }
  .order-shipping {
    margin: 0 0 em(20) 0;
    .label {
      font-weight: font-weight(lato-heavy);
    }
  }
  .order-total {
    margin: 0 0 em(20) 0;
    .label {
      text-transform: uppercase;
    }
    .value {
      font-weight: font-weight(lato-heavy);
    }
  }
  .order_canceled {
    color: color(red);
  }
}
.order-summary {
  @include respond-to(medium) {
    border-bottom: 2px solid color(extra-light-gray);
    margin: 0 0 em(30) 0;
  }
  .table-row {
    &.order-detail-summary {
      border-top: 2px solid color(extra-light-gray);
      border-bottom: 2px solid color(extra-light-gray);
      padding: em(30) 0;
      margin: 0;
    }
  }
}
.order-detail-summary {
  .order-totals-table {
    background-color: transparent;
  }
}
.wish-list {
  .cart-row {
    width: 100%;
    padding: 0 0 em(4) 0;
    margin: 0 0 em(30) 0;
    &.header-row {
      display: flex;
      font-family: font-family(lato);
      font-weight: font-weight(lato-regular);
      font-size: font-size(p);
      color: color(black);
      text-transform: uppercase;
      line-height: 1em;
      background-color: color(extra-light-gray);
      padding: em(14) em(15);
      margin: 0 0 em(30) 0;
    }
    .button-text,
    .button-text[type="submit"] {
      display: block;
      margin: 0 0 em(20) 0;
    }
    .item-label {
      font-size: font-size(p);
      font-weight: font-weight(lato-regular);
      color: color(black);
      text-transform: uppercase;
      margin: 0 0 em(10) 0;
    }
  }
  .header-row {
    .item-main {
      margin: 0;
    }
    .item-quantity {
      display: none;
      @include respond-to(medium) {
        display: block;
      }
    }
    .item-options {
      display: none;
      @include respond-to(medium) {
        display: block;
      }
    }
  }
  .item-options {
    @include respond-to(medium) {
      margin-left: auto;
    }
  }
  .product-availability-list {
    padding: 0 em(10) 0 0;
    margin: 0 0 em(5) 0;
  }
  .price {
    margin-top: em(10);
  }
  label {
    margin: 0 0 em(5) 0;
  }
  .notavailable {
    font-size: em(13);
    font-style: italic;
    line-height: 1.2em;
  }
}
