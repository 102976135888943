.tooltip {
	position: relative;
  cursor: help;
}

.tooltip-content {
	display: none;
}

.ui-tooltip.ui-widget-content {
  z-index: zindex(modal-window);
  background-color: color(extra-light-gray);
  border: 1px solid color(gray);
  width: 90vw;
	@include respond-to(medium) {
		display: inline-block;
		width: auto;
		max-width: 35vw;
	}
}

.ui-tooltip-content {
	line-height: 1.2em;
  padding: em(10);
	p {
		font-size: em(12);
		margin: 0 0 em(3) 0;
		&:nth-child(even) {
			margin: 0 0 em(13) 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}
