.primary-content {
  form {
    margin: 0 0 em(30) 0;
    @include respond-to(large) {
      width: 60%;
    }
  }
}

.primary-cart {
  form {
    margin: 0;
    @include respond-to(large) {
      width: auto;
    }
  }
}

legend {
  font-weight: font-weight(lato-heavy);
  .required-indicator {
    display: none;
  }
}

label {
  display: block;
  font-family: font-family(lato);
  font-weight: font-weight(lato-regular);
  font-size: font-size(label);
  .required-indicator {
    font-size: em(13);
    font-weight: font-weight(lato-light);
    color: color(gray);
    padding: 0 0 0 em(5);
  }
  .tooltip {
    display: inline-block;
    margin: 0 0 0 em(5);
  }
}

.form-label-text {
  .required-indicator {
    display: none;
  }
}

.form-caption {
  font-weight: font-weight(lato-light);
  font-size: em(13);
  color: color(gray);
}

.form-field-tooltip {
  font-weight: font-weight(lato-light);
  font-size: em(13);
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="number"] {
  display: block;
  font-family: font-family(lato);
  font-weight: font-weight(lato-regular);
  font-size: font-size(label);
  color: color(gray);
  background-color: color(extra-light-gray);
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: rem(40);
  padding: em(8) em(10);
  &.error {
    box-shadow: 0 0 0 2px color(red);
  }
}
input[type="number"] {
  padding: em(8) em(15) em(8) em(24);
  @include respond-to(medium) {
    padding: em(8) em(2) em(8) em(24);
  }
}
input[type=radio] {
  &:checked ~ .check {
    &::before {
      background: color(light-blue);
    }
  }
}
textarea {
  background-color: color(extra-light-gray);
  border: none;
  box-sizing: border-box;
  width: 100%;
  padding: em(8) em(10);
}
.select-wrapper {
  position: relative;
  display: inline-block;
  background-color: color(extra-light-gray);
  overflow: hidden;
  min-width: 100%;
  height: rem(40);
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: '';
    z-index: zindex(base);
    background-color: color(dark-blue);
    width: rem(40);
    height: rem(40);
  }
  &:after {
    position: absolute;
    top: 50%;
    right: em(10);
    transform: translateY(-50%);
    font-size: em(20);
    content: '';
    @include svg-sprite(arrow-icon-select, block);
    z-index: zindex(base);
  }
  select {
    position: relative;
    background: none;
    box-shadow: none;
    border: none;
    appearance: none;
    z-index: zindex(page);
    width: 100%;
    height: rem(40);
    padding: 0 em(70) 0 em(20);
    &:focus {
      outline: none;
    }
  }
}
.form-row {
  margin: 0 0 em(20) 0;
  &.form-row-submit {
    margin: em(30) 0 0 0;
  }

  a {
    color: #005ea6;
  }
}
.form-row-button {
  display: flex;
  flex-wrap: wrap;
  margin-top: em(30);
  button[type="submit"] {
    @include respond-to(medium) {
      margin-right: em(10);
    }
  }
  .cancel-button[type="submit"] {
    margin-left: auto;
    @include respond-to(medium) {
      margin-left: 0;
    }
  }
}
.label-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  line-height: 1.2em;
  .field-wrapper {
    margin-right: em(2);
  }
}
.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 em(10) 0;
  label {
    display: none;
  }
  .form-row {
    width: 100%;
    margin: 0;
    @include respond-to(medium) {
      width: auto;
      margin-right: em(10);
    }
    label {
      display: block;
    }
  }
  .error {
    width: 100%;
  }
}
.form-options {
  display: flex;
  font-size: font-size(label);
  align-items: baseline;
  .form-row {
    margin: 0;
  }
  label {
    color: color(gray);
  }
  a {
    margin-left: auto;
  }
}
span.error,
.error-message {
  display: block;
  font-size: font-size(label);
  color: color(red);
}
.error {
  input {
    box-shadow: 0 0 0 2px color(red);
  }
}
