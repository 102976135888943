.icon-menu {
  @include svg-sprite(menu-icon);
  background-color: transparent;
  padding: 0;
  margin: 0;
  &:hover {
  }
  &.active {
    @include svg-sprite(close-icon);
  }
}
.icon-search {
  font-size: em(24);
  @include svg-sprite(search-icon-white);
  background-color: transparent;
  padding: 0;
  margin: 0;
  &:hover {
    background-color: transparent;
    @include svg-sprite(search-icon-hover);
  }
  &:active,
  &:focus {
    @include svg-sprite(search-icon-white);
  }
}
.icon-minicart {
  display: inline-block;
  font-size: font-size(label);
  @include svg-sprite(cart-icon, block);
  @include respond-to(medium) {
  }
}
.minicart-quantity {
  @include svg-sprite(cart-quantity-icon);
  display: none;
  font-family: helvetica;
  font-weight: bold;
  font-size: em(14);
  color: color(dark-blue);
  text-align: center;
  line-height: 1.3em;
  box-sizing: border-box;
  padding-left: em(7);
  @include respond-to(large) {
    display: block;
    margin-left: em(8);
  }
}
.icon-arrow-expand {
  font-size: em(14);
  @include svg-sprite(arrow-icon);
  &.expanded {
    @include svg-sprite(arrow-icon-select);
  }
}
.icon-arrow-expand-active {
  @include svg-sprite(arrow-icon-select);
}
.loading-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: zindex(loading);
  width: 100%;
  height: 100%;
  span {
    display: block;
    transform: translate(0, -50%);
    background: transparent url('../../images/icon-loading.svg') 0 0 no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
    margin: 50% auto 0 auto;
  }
}
.loading-full {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.12);
  span {
    transform: none;
    margin: 0;
  }
}

.social-item-link {
  font-size: 5px;
  text-indent: -9999px;
  &.facebook {
    @include svg-sprite(facebook);
    &:hover {
      @include svg-sprite(facebook-hover);
    }
  }
  &.twitter {
    @include svg-sprite(twitter);
    &:hover {
      @include svg-sprite(twitter-hover);
    }
  }
  &.instagram {
    @include svg-sprite(instagram);
    &:hover {
      @include svg-sprite(instagram-hover);
    }
  }
  &.youtube {
    @include svg-sprite(youtube);
    &:hover {
      @include svg-sprite(youtube-hover);
    }
  }
  &.pinterest {
    @include svg-sprite(pinterest);
    &:hover {
      @include svg-sprite(pinterest-hover);
    }
  }
}
