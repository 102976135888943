.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: zindex(modal-overlay);
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
}
.ui-dialog {
  position: fixed;
  z-index: zindex(modal-window);
  background-color: color(white);
  box-sizing: border-box;
  outline: none;
  width: 80%;
	padding: em(20);
  @include respond-to(medium) {
    position: absolute;
    width: 60%;
  }
  h1 {
    @extend %h4;
    text-align: left;
    text-transform: uppercase;
    background-color: transparent;
    padding: 0;
  }
  .ui-dialog-title {
    display: none;
  }
  .ui-dialog-titlebar {
    float: right;
  }
  .ui-dialog-titlebar-close {
    display: block;
    text-align: right;
    vertical-align: bottom;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
	.ui-icon-closethick {
    @include svg-sprite(close-icon);
	}
  .ui-button-text {
    display: none;
  }
  .account-modal-form {
    overflow: auto;
    height: 100vh;
    @include respond-to(medium) {
      height: auto;
    }
  }
  .fieldset {
    margin: 0 0 em(40) 0;
  }
  label {
    font-weight: font-weight(lato-regular);
    font-size: font-size(label);
    span {
      margin-right: em(3);
    }
  }
  legend {
    font-weight: font-weight(lato-heavy);
  }
  button[type="submit"] {
    @extend %button;
    @extend %button-submit;
  }
  .cancel-button[type="submit"] {
    @extend %button-text;
    margin: 0;
    &:hover {
      color: color(light-blue-hover);
      background-color: transparent;
    }
  }
  .dialog-required {
    display: none;
  }
}
.no-close .ui-dialog-titlebar-close {
	display: none;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-container {
    position: relative;
    background-color: color(white);
    overflow-y: scroll;
    box-sizing: border-box;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: em(20);
    @include respond-to(medium) {
      width: auto;
      height: auto;
      max-width: 95%;
      max-height: 80%;
    }
    @include respond-to(xlarge) {
      max-width: 75%;
    }
  }
  .close-icon {
    @include svg-sprite(close-icon);
    position: absolute;
    top: em(40);
    right: em(40);
    font-size: em(12);
    cursor: pointer;
  }
}
