#mini-cart {
  &.dropdown-active {
    overflow: visible;
    .icon-minicart {
      @include svg-sprite(cart-icon-select, block);
    }
  }
}
.minicart-link {
  display: flex;
  align-items: center;
  .hidden {
    display: none;
  }
}
.mini-cart-content {
  position: fixed;
  top: 0;
  right: 0;
  z-index: zindex(modal-window);
  font-family: font-family(lato);
  color: color(black);
  background-color: color(extra-light-gray);
  overflow: auto;
  min-width: 30vw;
  width: 100%;
  max-height: 0;
  @include respond-to(medium) {
    position: absolute;
    top: calc(100% + 14px);
    overflow: hidden;
    background-color: color(extra-light-gray);
    transition: max-height transition(dropdown);
    width: auto;
  }
  @include respond-to(xlarge) {
    min-width: 25vw;
  }
  @include respond-to(full-size) {
    min-width: 20vw;
  }
  a {
    color: color(light-blue);
  }
  .mini-cart-link-cart,
  .mini-cart-link-checkout {
    @extend %button;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto em(20) auto;
    @include respond-to(medium) {
      display: inline-block;
      width: 43%;
      height: auto;
    }
  }
  .mini-cart-link-cart {
    @extend %button-secondary;
    @include respond-to(medium) {
      font-size: font-size(base);
      padding: em(10);
      margin: 0 em(20) 0 0;
    }
  }
  .mini-cart-link-checkout {
    @extend %button-primary;
    @include respond-to(medium) {
      font-size: font-size(base);
      padding: em(10);
      margin-bottom: 0;
    }
  }
}
.mini-cart-header {
  position: relative;
  color: color(black);
  cursor: pointer;
  line-height: em(53);
  padding: em(10) em(20);
  margin: 0;
  .cart-close {
    @include svg-sprite(close-icon-blue, block);
    font-size: em(9);
    cursor: pointer;
    text-indent: -9999px;
  }
}
.mini-cart-products {
  background-color: color(white);
  border-left: 1px solid color(extra-light-gray);
  border-right: 1px solid color(extra-light-gray);
  padding: em(20);
  @include respond-to(medium) {
    overflow: auto;
    max-height: 50vh;
  }
}
.mini-cart-subtotals {
  font-weight: font-weight(lato-heavy);
  background-color: color(medium-light-gray);
  padding: em(5) em(20);
  margin: 0 0 em(20) 0;
  span {
    box-sizing: border-box;
    text-align: right;
    white-space: nowrap;
    width: 50%;
  }
  .label {
    text-align: left;
  }
}
.mini-cart-totals {
  text-align: center;
  padding: 0 0 em(20) 0;
  @include respond-to(medium) {
    border-left: 1px solid color(medium-light-gray);
    border-right: 1px solid color(medium-light-gray);
    border-bottom: 1px solid color(medium-light-gray);
  }
}
.mini-cart-product {
  font-size: em(14);
  color: color(gray);
  line-height: 1.4em;
  border-bottom: 1px dotted color(medium-light-gray);
  padding: 0 0 em(10) 0;
  margin: 0 0 em(20) 0;
  &:last-of-type {
    border: none;
    padding: 0;
    margin: 0;
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
  .notavailable:last-child {
    font-size: em(13);
    font-style: italic;
    line-height: 1.2em;
    margin-top: em(10);
  }
}
.mini-cart-image {
  padding: 0px 5px;
  box-sizing: border-box;
  a {
    display: block;
  }
}
.mini-cart-name {
  font-family: font-family(korolev);
  font-size: font-size(base);
  font-weight: font-weight(korolev-medium);
  margin: 0 0 em(5) 0;
}
.mini-cart-price {
  display: block;
  font-weight: font-weight(lato-heavy);
  color: color(black);
  margin-top: em(5);
}
.mini-cart {
  &.active {
    .mini-cart-content {
      height: 100vh;
      max-height: 100vh;
      @include respond-to(medium) {
        height: auto;
      }
    }
  }
}

