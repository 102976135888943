@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
  }

  @else {
  @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin typekit-loading($array) {
  @each $selector in $array {
    #{$selector} {
      opacity: 0;
    }
  }
}
@mixin typekit-loaded($array) {
  @keyframes fadeInFonts {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @each $selector in $array {
    #{$selector} {
      animation-name: fadeInFonts;
      animation-duration: .4s;
    }
  }
}
@mixin typekit-foit() {
  .wf-loading {
    #{headings(1,4)} {
      opacity: 0;
    }
    @include typekit-loading($typekit-loading);
  }
  @each $font in $typekit-loaded {
    #{$font} {
      @include typekit-loaded($typekit-loading);
    }
  }
}
