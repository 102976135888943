.no-hits-header {
  text-align: center;
  background-image: url('../../images/banner-default.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  height: rem(155);
  @include respond-to(medium) {
    height: rem(205);
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: em(10);
    @include respond-to(medium) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: em(20);
    }
  }
  h1 {
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    font-size: font-size(h2);
    color: color(white);
    text-align: center;
    margin: 0 auto;
    @include respond-to(medium) {
      font-size: font-size(h1-mobile);
    }
  }
}

.no-hits-search {
  p {
    &:last-of-type {
      margin: 0 em(10) 0 0;
    }
  }
  button[type="submit"] {
    @extend %button-primary;
    font-family: font-family(lato);
    font-weight: font-weight(lato-regular);
    font-size: font-size(label);
    height: rem(40);
    margin: 0;
  }
}

.no-hits-terms {
  a {
    font-weight: font-weight(lato-light);
  }
}

.no-hits-category-list {
  @include respond-to(medium) {
    flex-wrap: wrap;
  }
  li {
    @include respond-to(medium) {
      flex: 0 1 20%;
    }
  }
  .category-top {
    font-weight: font-weight(lato-light);
  }
}

.no-hits-popular-products {
  .product-image {
    max-width: em(333);
  }
}
