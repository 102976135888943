.ps-container {
  &.ps-active-y {
    > .ps-scrollbar-y-rail {
      background-color: color(extra-light-gray);
      opacity: 1;
      border-radius: 5px;
      width: 7px;
      .ps-scrollbar-y {
        background-color: color(gray);
        right: 0;
        width: 7px;
      }
    }
  }
}
