.primary-cart {
  padding: 0 0 em(20) 0;
  @include respond-to(medium) {
    box-sizing: border-box;
    max-width: breakpoint(large);
    margin: 0 auto;
  }
  .cart-contents-form {
    margin: 0 0 em(20);
  }
  h1 {
    margin: 0 auto em(20);
  }
  .visually-hidden {
    display: none;
  }
  .order-totals-table  {
    background-color: transparent;
    padding: 0 0 em(20) 0;
  }
  .cart-action-continue-shopping {
    margin: 0 0 em(40) 0;
  }
  .error-form {
    color: color(red);
    margin: 0 0 em(20) 0;
  }
  .cart-subtotals {
    justify-content: flex-end;
  }
}
.cart-contents-form {
  h1 {
    .divider,
    .count-items {
      display: none;
    }
  }
}
.cart-empty {
  text-align: center;
  h2 {
    margin: 0 0 em(5) 0;
  }
  .cart-action-continue-shopping {
    text-align: center;
    margin-top: em(30);
    .button-text[type="submit"] {
      display: inline-block;
    }
  }
}
.cart-action-continue-shopping {
  .button-text[type="submit"] {
    display: block;
    font-size: font-size(label);
    text-align: left;
    line-height: 1.2em;
    margin: 0;
  }
}

.cart-row {
  line-height: 1.2em;
  border-bottom: 1px solid color(extra-light-gray);
  padding: 0 em(20) em(4) em(20);
  margin-bottom: em(15);
  @include respond-to(medium) {
    border: none;
  }
  &:last-child {
    margin-bottom: em(30);
  }
  &.header-row {
    font-weight: font-weight(lato-heavy);
    font-size: font-size(h4);
    color: color(white);
    background-color: color(dark-blue);
    padding: em(20);
  }
  > div {
    margin: 0 0 em(20) 0;
    @include respond-to(medium) {
      padding: 0 em(20) 0 0;
      margin: 0;
      &:last-child {
        padding: 0;
      }
    }
  }
  .item-label {
    font-weight: font-weight(lato-heavy);
    color: color(gray);
  }
  .item-main {
    margin: 0 0 em(40) 0;
    @include respond-to(medium) {
      margin: 0;
    }
  }
  .item-image {
    margin: 0 0 em(20) 0;
    @include respond-to(medium) {
      margin: 0;
    }
  }
  object[type="image/png"] {
    width: 135px;
  }
  .item-details {
    margin: 0 auto;
    @include respond-to(medium) {
      margin: 0;
    }
  }
  .product-list-item {
    margin: 0 0 em(12) 0;
  }
  .brand {
    font-weight: font-weight(lato-heavy);
    font-size: em(14);
    color: color(dark-blue);
    margin: 0 0 em(5) 0;
  }
  .name {
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-medium);
    font-size: em(24);
    line-height: 1.1em;
    margin: 0 0 em(3) 0;
    a {
      color: color(dark-blue);
    }
  }
  .item-price {
    font-weight: font-weight(lato-heavy);
  }
  .price-standard {
    display: block;
    font-style: italic;
    color: color(gray);
    text-decoration: line-through;
    margin: 0 0 em(5) 0;
  }
  .price-sales {
    display: block;
    font-weight: font-weight(lato-heavy);
  }
  .product-availability-list {
    font-weight: font-weight(lato-heavy);
    color: color(gray);
  }
  .item-quantity {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include respond-to(medium) {
      display: block;
    }
  }
  .item-quantity-details {
    display: flex;
    justify-content: flex-end;
    margin-top: em(10);
    margin-left: auto;
  }
  .input-text {
    width: 3.5rem;
    margin: 0 em(3) em(5) 0;
    @include respond-to(medium) {
      margin-right: 0;
    }
  }
  .button-text[type="submit"],
  .add-to-wishlist {
    display: block;
    font-size: font-size(label);
    margin: 0 0 em(15) 0;
  }
  .sku {
    display: none;
  }
}

.item-details {
  .value {
    font-weight: font-weight(lato-heavy);
  }
}

.cart-coupon-content {
  input[type="text"],
  .button-secondary {
    height: rem(40);
  }
  .button-secondary {
    font-size: font-size(h3, true);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cart-coupon-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: '';
    transition: transform 0.1s linear;
    font-size: em(9);
    vertical-align: middle;
    @include svg-sprite(arrow-right);
    @include respond-to(medium) {
      display: none;
    }
  }
}
.dropdown-animate,
.dropdown-leave {
  .cart-coupon-title {
    &::after {
      transform: rotate(90deg);
    }
  }
}
.dropdown-active {
  .cart-coupon-title {
    &::after {
      transform: rotate(90deg);
    }
  }
}
