.pdp-main {
  display: flex;
  flex-wrap: wrap;
  .product-image-container,
  .product-detail {
    box-sizing: border-box;
    width: 100%;
    @include respond-to(medium) {
      width: 50%;
    }
  }
  .product-external-url {
    text-align: center;
    width: 100%;
    @include respond-to(medium) {
      width: 50%;
      margin-left: auto;
    }
  }
  .product-image-container {
    margin: 0 0 em(20) 0;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    @include respond-to(medium) {
        margin: 0 0 em(50) 0;
    }
  }
  .product-detail {
    padding: em(10);
    margin: 0 0 em(20) 0;
    @include respond-to(medium) {
        padding: em(20) em(10);
    }
  }
  h1 {
    font-size: font-size(h2);
    color: color(dark-blue);
    margin: 0 0 rem(15) 0;
    @include respond-to(medium) {
      font-size: font-size(h1-mobile);
    }
  }
  h2 {
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    font-size: em(24);
    color: color(dark-blue);
    text-align: center;
    margin: 0;
    @include respond-to(medium) {
      font-size: em(35);
    }
  }
  form {
    margin: 0;
  }
  .product-copy {
    @include respond-to(medium) {
      width: 65%;
      margin: 0 auto;
    }
  }
  .product-price {
    text-align: center;
    margin: 0 0 em(20) 0;
    span {
      display: block;
    }
  }
  .price-reduced {
    font-size: font-size(h4);
    font-style: italic;
    color: color(gray);
    text-decoration: line-through;
    line-height: 1em;
    margin: 0 0 em(5) 0;
  }
  .price-sales {
    font-size: em(22);
    line-height: 1em;
    @include respond-to(medium) {
      font-size: em(30);
    }
  }
  .swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include respond-to(small) {
      width: 60%;
    }
    @include respond-to(medium) {
      width: 90%;
    }
    @include respond-to(medium-large) {
      width: 90%;
    }
    @include respond-to(large) {
      width: 60%;
    }
    @include respond-to(xlarge) {
      width: 56%;
    }
  }
  .product-copy {
    background-color: color(extra-light-gray);
  }
  .add-to-cart,
  .add-to-cart-disabled {
    @extend %button;
    @extend %button-primary;
    display: block;
    margin: 0 auto em(5) auto;
  }
  .out-of-stock {
    display: block;
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-medium);
    font-size: font-size(h2, true);
    color: color(gray);
    cursor: not-allowed;
    margin: 0 auto em(5) auto;
  }
  .promotion,
  .visually-hidden {
    display: none;
  }
}
.product-primary-image {
  margin: 0 auto;
  width: 100%;
  @include respond-to(medium) {
    order: 2;
    width: 65%;
  }
}
.product-image {
  position: relative;
  display: block;
  margin: 0 auto;
  object {
    width: 100%;
  }
  .zoomImg {
    cursor: url('../../images/icon-zoom.png'), auto;
  }
}
.product-thumbnails {
  order: 1;
  width: 100%;
  @include respond-to(medium) {
    width: 30%;
  }
  h2 {
    display: none;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: em(30);
    @include respond-to(medium) {
      flex-direction: column;
      margin: 0;
    }
  }
  .thumb {
    text-align: center;
    margin: 0 em(5);
  }
  .thumbnail-link {
    display: block;
    width: rem(70);
    @include respond-to(medium) {
      width: rem(88);
    }
  }
  p {
    display: none;
  }
}
.product-variation {
  li {
    display: inline-block;
    margin: 0 rem(5) rem(10) rem(5);
    border: 1px solid transparent;
    &:hover {
      border-color: color(dark-blue);
    }
    &.attribute {
      display: block;
      margin: 0 0 em(20) 0;
    }
  }
  a {
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px solid color(dark-blue);
    min-width: em(40);
    min-height: em(30);
    padding: em(2) em(5);
  }
  .selected {
    border-color: color(dark-blue);
    a {
      color: color(white);
      background-color: color(dark-blue);
    }
  }
  .unselectable {
    &:hover,
    &.selected {
      border-color: color(dark-blue);
      a {
        border-color: color(dark-blue);
      }
    }
    a {
      color: color(medium-gray);
      border-color: color(medium-gray);
      background-color: transparent;
    }
  }
  .selected-value {
    display: none;
  }
}
.product-variation-color {
  li {
    border: 2px solid transparent;
    margin: 0 rem(4) rem(10) rem(4);
    &:hover {
      border-color: color(dark-blue);
    }
  }
  a {
    overflow: hidden;
    width: rem(40);
    height: rem(26);
  }
  .selected {
    border-color: color(dark-blue);
  }
}
.product-options {
  text-align: center;
  form {
    width: 100%;
  }
  .label,
  label {
    font-weight: font-weight(lato-heavy);
    margin: 0 0 em(5) 0;
  }
  label {
    margin-right: em(10);
  }
  input[type="text"] {
    text-align: center;
    min-width: 30%;
    width: auto;
    @include respond-to(medium) {
      min-width: 4vw;
    }
  }
  .inventory {
    margin: 0 0 em(20) 0;
  }
  .quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    label {
      width: 100%;
    }
  }
}
.product-sku {
  display: none;
}
.product-actions {
  a {
    display: block;
  }
}
.product-description {
  font-size: font-size(h4);
  background-color: color(extra-light-gray);
  padding: em(20) 0;
  .container {
    padding-top: 0;
    padding-bottom: 0;
    @include respond-to(medium) {
      width: 75%;
    }
    @include respond-to(large) {
      width: 60%;
    }
    @include respond-to(full-size) {
      width: 40%;
    }
  }
  h3 {
    text-align: center;
  }
  p {
    &:last-of-type {
      margin: 0 0 em(20) 0;
    }
  }
  .label {
    font-weight: font-weight(lato-heavy);
  }
}
.product-size-chart {
  padding-top: em(30);
  table {
    border-bottom: 1px solid color(extra-light-gray);
    min-width: 40%;
    width: auto;
    margin: 0 auto;
  }
  caption {
    font-family: font-family(korolev);
    font-size: font-size(h3);
    font-weight: font-weight(korolev-bold);
    color: color(dark-blue);
    text-align: center;
    width: 100%;
    margin: 0 0 em(10) 0;
  }
  tbody {
    height: auto;
    margin: 0;
  }
  th,
  td {
    text-align: center;
    border-left: 1px solid color(extra-light-gray);
    border-right: 1px solid color(extra-light-gray);
    border-bottom: 1px solid color(extra-light-gray);
  }
  th {
    font-weight: font-weight(lato-heavy);
    color: color(white);
    background-color: color(dark-blue);
    border-color: color(gray);
    padding: em(10) em(15);
  }
  td {
    padding: em(10) em(15);
    &:first-child {
      background-color: inherit;
      color: color(black);
    }
  }
  .mobile-label {
    display: none;
  }
  .mobile-value {
    text-align: center;
    width: auto;
    padding: 0;
  }
  tfoot {
    tr {
      td {
        border-color: transparent;
      }
      &:nth-child(odd) {
        td {
          background-color: transparent;
        }
      }
    }
  }
  .scroll-up,
  .scroll-down {
    display: none;
  }
}
.product-size-chart-mobile {
  table,
  tbody,
  th,
  tr {
    display: block;
  }
  table {
    position: relative;
    width: 100%;
  }
  caption {
    display: block;
  }
  thead {
    display: none;
  }
  tbody {
    position: relative;
    overflow: hidden;
    height: 50vh;
  }
  tr {
    &:first-child {
      display: none;
    }
    &:nth-child(odd) {
      background-color: color(extra-light-gray);
      td {
        border-bottom: 1px solid lighten(color(gray), 40%);
      }
    }
  }
  th {
    padding: em(10) em(15);
  }
  td {
    position: relative;
    display: flex;
    padding: em(15) 0;
    &:first-child {
      background-color: color(dark-blue);
      color: color(white);
    }
  }
  .mobile-label,
  .mobile-value {
    line-height: 1em;
    width: 50%;
    padding: 0 em(10);
  }
  .mobile-label {
    font-weight: 700;
    text-align: right;
    @include respond-to(medium-large) {
      display: none;
    }
  }
  .mobile-value {
    text-align: left;
  }
  tfoot {
    tr {
      td {
        border-color: transparent;
      }
      &:nth-child(odd) {
        td {
          background-color: transparent;
        }
      }
    }
  }
  .scroll-up,
  .scroll-down {
    display: block;
    position: absolute;
    right: -2px;
    z-index: 2;
  }
  .scroll-up {
    top: -5px;
    @include svg-sprite(arrow-icon-select-up);
  }
  .scroll-down {
    top: calc(100% + 5px);
    @include svg-sprite(arrow-icon-select);
  }
}
.important-product-message {
  p {
    margin: spacing(paragraph) 0;
  }
}
.pdp-reviews {
  margin-top: mm(3);
  h2 {
    font-size: font-size(h2);
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    text-align: center;
  }
}


.sellout {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 0 0 em(20);
  b {
    font-size: font-size(h4);
    margin-right: spacing(element);
  }
  .icon {
    align-self: center;
    margin-right: spacing(element);
  }
}

.sellout-hurry {
  .icon {
    @include svg-sprite(hourglass-icon-yellow);
  }
}

.sellout-order-now {
  .icon {
    @include svg-sprite(hourglass-icon-red);
  }
}
