.primary-support {
  .confirmation-message {
    h2 {
      margin: 0 0 em(5) 0;
    }
  }
}
.cs-header {
  font-family: font-family(korolev);
  font-weight: font-weight(korolev-bold);
  text-transform: uppercase;
}
.need-help {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border: 1px solid color(extra-light-gray);
  max-width: breakpoint(large);
  margin: 0 auto em(50) auto;
  li {
    font-size: em(16);
    box-sizing: border-box;
    width: 100%;
    padding: em(20) 0;
    @include respond-to(medium) {
      width: 33.3%;
      padding: em(10) em(20);
    }
    &:first-child {
      font-family: font-family(korolev);
      font-weight: font-weight(korolev-bold);
      font-size: em(22);
      text-transform: uppercase;
      background-color: color(extra-light-gray);
      width: 100%;
      padding: em(5) 0;
    }
  }
  h4 {
    margin: 0;
  }
}
.support-answers {
  .topic {
    border-bottom: 1px solid color(extra-light-gray);
    padding: 0 0 em(20) 0;
    margin: 0 0 em(40) 0;
    &:last-of-type {
      border: none;
      padding: 0;
    }
  }
  h3 {
    margin: 0;
  }
  ul {
    margin: 0 0 em(30) 0;
  }
  li {
    list-style: default;
    margin: 0 0 em(5) em(20);
  }
}
.support-answers-table {
  width: 100%;
  margin: 0 0 em(20) 0;
  caption {
    display: block;
    font-weight: font-weight(lato-heavy);
    text-align: left;
    text-transform: capitalize;
    margin: 0 0 em(2) 0;
  }
  tr {
    border-left: 1px solid color(extra-light-gray);
    border-right: 1px solid color(extra-light-gray);
    &:first-child {
      border: none;
      td {
        color: color(white);
        background-color: color(gray);
      }
    }
    &:nth-child(even) {
      td {
        background-color: color(extra-light-gray);
      }
    }
  }
  td {
    border-bottom: 1px solid color(extra-light-gray);
    padding: 0 em(20);
  }
}
.contact-departments {
  border-top: 2px solid color(extra-light-gray);
  padding-top: em(40);
}
.support-secondary {
  line-height: 1.2em;
  ul {
    margin: 0 0 em(30) 0;
  }
  li {
    margin: 0 0 em(10) 0;
  }
}
.non-authenticated-support {
  form {
    width: 100%;
  }
}
