.pagination {
  margin: 0 0 em(15) 0;
  .results-hits {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0 0 em(10) 0;
    @include respond-to(medium) {
      text-align: left;
      width: auto;
      margin-right: auto;
    }
  }
  .pagination-controls {
    display: flex;
    float: clear;
    justify-content: center;
    align-items: center;
    color: color(dark-blue);
    @include respond-to(medium) {
      display: inline-flex;
      float: right;
      justify-content: flex-end;
    }
  }
  .page-count {
    font-size: em(15);
    margin: 0 em(20);
  }
  .page-previous {
    @include svg-sprite(arrow-left);
    font-size: rem(10);
    text-indent: -9999px;
  }
  .page-next {
    @include svg-sprite(arrow-right);
    font-size: rem(10);
    text-indent: -9999px;
  }
  .disabled {
    opacity: .3;
    cursor: not-allowed;
  }
}
.items-per-page {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 0 em(30) 0;
  @include respond-to(medium) {
    justify-content: flex-end;
  }
  label {
    font-size: font-size(label);
    font-weight: font-weight(lato-regular);
    margin-right: em(5);
  }
  a,
  .selected {
    display: block;
    text-transform: uppercase;
    line-height: 1em;
    border-right: 1px solid color(gray);
    padding: 0 em(7);
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
  .selected {
    text-decoration: underline;
  }
}
