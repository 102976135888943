footer {
  background: color(dark-blue);
  color: color(white);
  text-align: center;
  flex-shrink: 0;
  padding: em(20) 0;
  ul {
    margin: 0 0 em(40) 0;
    @include respond-to(large) {
      margin: 0 0 em(20) 0;
    }
  }
  li {
    @include respond-to(large) {
      display: inline-block;
    }
  }
  a {
    display: block;
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-medium);
    font-size: font-size(h4);
    color: color(white);
    text-transform: uppercase;
    line-height: em(40);
    padding: 0 em(5);
    margin: 0 em(5);
    &:hover {
      color: color(light-blue);
    }
  }
  .copyright {
    font-family: font-family(lato);
    font-weight: font-weight(lato-regular);
    font-style: italic;
    font-size: em(12);
    line-height: 1.1em;
  }
  .content-asset {
    display: inline-block;
  }
}
