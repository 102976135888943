.tiles-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0 0 em(60) 0;
}
.grid-tile {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 em(30) 0;
  @include respond-to(medium) {
    width: 50%;
  }
  @include respond-to(large) {
    width: 33.3%;
  }
  .product-tile {
    padding: 0 em(20);
    &:hover {
      a {
        color: color(light-blue);
      }
    }
  }

  .product-image {
    margin: 0 auto em(20) auto;
  }
  object {
    display: block;
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    color: color(black);
    line-height: 1.2em;
  }
  .quickview {
    display: none;
  }
  .product-name {
    @extend %h4;
    margin: 0 0 em(10) 0;
    @include respond-to(medium) {
      padding: 0 em(30);
    }
    @include respond-to(large) {
      padding: 0 em(20);
    }
    @include respond-to(xlarge) {
      padding: 0 em(30);
    }
  }
  .product-pricing {
    line-height: 1em;
    margin: 0 0 em(10) 0;
    span {
      display: block;
    }
  }
  .product-reduced-price {
    font-size: em(14);
    font-weight: font-weight(lato-light);
    font-style: italic;
    color: color(gray);
    text-decoration: line-through;
    margin: 0 0 em(5) 0;
  }
  .product-sales-price {
    font-size: em(16);
    font-weight: font-weight(lato-heavy);
  }
  .product-swatches-all {
    display: none;
  }
  .swatch-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
    margin: 0 auto;
    li {
      margin: 0 em(5) em(5) em(5);
    }
    a {
      border: 1px solid transparent;
      overflow: hidden;
      width: em(25);
      height: em(15);
    }
    .selected {
      border: 1px solid color(black);
    }
  }
}
.no-items-in-stock {
  opacity: 0.25;
}
.out-of-stock-text {
  width: 100%;
  position: absolute;
  transform: translate(-50%, 50%);
  font-weight: font-weight(lato-heavy);
  font-size: 20px;
  left: 50%;
  bottom: 50%;
  z-index: zindex(page);
  opacity: 0.8;
}
