.nav-brands-visual {
  @include respond-to(medium-large) {
    background-color: color(black);
  }
  .container {
    display: flex;
    flex-direction: column;
    @include respond-to(medium-large) {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
    }
  }
  a {
    display: block;
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    font-size: em(24);
    color: color(black);
    text-align: center;
    box-sizing: border-box;
    margin: 0 0 em(7) 0;
    @include respond-to(medium-large) {
      flex: 0 20%;
      margin: 0;
    }
  }
  .flex-cell {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    vertical-align: middle;
    box-sizing: border-box;
    height: rem(70);
    @include respond-to(medium-large) {
      height: rem(200);
      padding: em(10) em(20);
      margin: em(10);
    }
  }
  img {
    display: none;
    @include respond-to(medium) {
      display: block;
      width: 100%;
    }
    @include respond-to(medium-large) {
      width: 80%;
    }
    @include respond-to(large) {
      width: 55%;
    }
  }
  span {
    display: block;
    width: 100%;
  }
}

.nav-category-visual {
  @include respond-to(medium-large) {
    background-image: none;
    background-color: transparent;
  }
  a {
    font-size: font-size(base);
    border: none;
    padding: 0;
  }
}
.informational {
  font-size: font-size(h4, true);
  padding: em(20);
  text-align: center;
}
