.error-content {
  text-align: center;
  h2 {
    margin: 0 0 em(5) 0;
  }
  img {
    width: auto;
  }
  .search-desktop {
    display: block;
    margin: em(30) 0;
  }
  form {
    @include respond-to(medium) {
      width: 40%;
      margin: 0 auto;
    }
  }
}
