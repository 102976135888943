.top-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 60px 0;
  form {
    &[role="search"] {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .search-desktop {
    @include respond-to(medium) {
      min-width: em(290);
      width: 24%;
    }
  }
}
.top-content-home {
  padding: 0;
}
