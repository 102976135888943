.refinement-header {
  display: none;
}
.refinement {
  @extend %refinement-container;
  &:last-of-type {
    margin: 0 0 em(10) 0;
  }
  &.expanded {
    ul {
      display: block;
      max-height: 100vh;
    }
  }
  h3 {
    position: relative;
    @extend %refinement-header;
    &.expanded {
      .fa {
        @include svg-sprite(minus-icon);
      }
    }
    .fa {
      position: absolute;
      top: 50%;
      right: em(10);
      transform: translateY(-50%);
      @include svg-sprite(add-icon);
    }
  }
  ul {
    font-size: em(16);
    transition: max-height transition(dropdown);
    overflow: hidden;
    max-height: 0;
  }
  li {
    &:first-of-type {
      margin-top: em(10);
    }
    &:last-of-type {
      margin-bottom: em(10);
    }
    .fa {
      display: block;
      position: absolute;
      top: 50%;
      right: rem(12);
      transform: translateY(-50%);
      font-size: 7px;
      @include svg-sprite(close-icon-white);
    }
  }
  a {
    position: relative;
    display: block;
    line-height: 1em;
    padding: em(9) em(10);
    margin: 0 0 em(5) 0;
  }
  .selected,
  .active {
    color: color(white);
    background-color: color(dark-blue);
    a {
      color: color(white);
    }
  }
  .clear-refinement {
    display: none;
  }
}
.clear-all-filters {
  display: block;
  font-size: em(15);
  text-align: right;
  text-transform: uppercase;
}
.refinement-sort-by {
  font-size: rem(12);
  text-transform: uppercase;
  border: none;
  margin-top: em(20);
  h4 {
    display: block;
    font-weight: font-weight(lato-heavy);
    font-size: rem(12);
    margin: 0 0 em(5) 0;
  }
  a {
    display: inline-block;
    padding: em(9) em(10);
    margin: 0 em(5) em(15) 0;
    &:first-of-type {
      padding-left: 0;
      &.selected {
        padding-left: em(9);
      }
    }
  }
  .form-row-checkbox {
    display: flex;
    line-height: 1em;
  }
  input {
    margin: 0 em(5) 0 0;
  }
}
.filters-active {
  font-size: em(12);
  .breadcrumb-refinement-name {
    display: none;
  }
  .breadcrumb-refined-by {
    font-style: italic;
    font-weight: font-weight(lato-regular);
    margin: 0 em(10) 0 0;
  }
  .breadcrumb-refinement-value {
    font-weight: font-weight(lato-heavy);
    background-color: color(extra-light-gray);
    padding: em(2) em(10);
    margin: 0 em(10) 0 0;
  }
  .breadcrumb-relax {
    display: inline-block;
    font-size: em(20);
    padding: 0 em(2);
    margin: 0 0 0 em(5);
  }
}
