.bottomline-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: mm(1);
  #yotpo-bottomline-top-div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: color(white);
  }
  .yotpo-display-wrapper {
    display: flex;
    justify-content: center;
    .text-m {
      display: none;
    }
  }
}
