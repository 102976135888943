.main-industry {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  align-content: flex-start;
  section {
    box-sizing: border-box;
    width: 100%;
    padding: em(20);
    @include respond-to(medium) {
      width: 50%;
      padding: em(40);
    }
    &.industry-banner {
      width: 100%;
      padding: 0;
    }
    &.industry-copy {
      flex: auto;
      @include respond-to(medium) {
        flex-grow: 1;
      }
      img {
        width:75%;;
        margin-bottom:30px;
      }
      ol {
        list-style-type:decimal;
        list-style-position:inside;
      }
      ul {
       list-style-type:disc;
       list-style-position:inside;
      }
      .psia-help {
        font-style:italic;
      }
    }
    &.industry-form {
      flex: auto;
      background-color: color(extra-light-gray);
      padding-right: em(20);
      padding-left: em(20);
      @include respond-to(medium) {
        flex-grow: 1;
      }
      @include respond-to(xlarge) {
        padding-right: em(120);
        padding-left: em(120);
      }
    }
    &.login-secondary {
      width: 100%;
      padding: em(60) 0;
    }
  }
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="search"],
  input[type="number"] {
    background-color: color(white);
    border: 1px solid color(medium-gray);
  }
  .select-wrapper {
    background-color: color(white);
    border: 1px solid color(medium-gray);
  }
  .input-radio {
    font-size: 14px;
    margin: 0 10px 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .label-radio {
    font-size: 14px;
  }
  textarea {
    background-color: color(white);
    border: 1px solid color(medium-gray);
  }
  .no-wrap {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
.snowshoe-type {
  display: flex;
  width: 100%;
  label {
    display: block;
  }
}

.industry-banner {
  img {
    display: block;
  }
  .banner-mobile {
    @include respond-to(medium) {
      display: none;
    }
  }
  .banner-desktop {
    display: none;
    @include respond-to(medium) {
      display: block;
    }
  }
}

.container-industry-success {
  margin-top: em(40);
  section {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 auto em(40);
  }
  .button-primary {
    margin-top: em(20);
  }
  img {
    max-width: em(200);
  }
}
