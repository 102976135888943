.search-simple-active {
  position: fixed;
  @include respond-to(medium) {
    position: relative;
  }
}
.search-mobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: em(20);
  @include respond-to(medium-large) {
    display: none;
  }
  &.search-active {
    .search-simple-container {
      position: fixed;
      top: 0;
      left: 0;
      z-index: zindex(modal-window);
      background-color: color(white);
      width: 100%;
      height: 100vh;
    }
  }
  .search-simple-container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    padding: rem(20) rem(20);
    margin: 0;
  }
  input[type="text"] {
    width: auto;
    height: rem(50);
  }
  button[type="submit"] {
    display: none;
  }
  .button-clear {
    min-width: 0;
    height: rem(25);
  }
}

.search-desktop {
  position: relative;
  display: none;
  margin: 0 0 em(10) auto;
  @include respond-to(medium-large) {
    display: block;
  }
  form {
    padding: 0 em(10);
  }
  input[type="text"] {
    background-color: transparent;
    width: auto;
  }
  button[type="submit"] {
    @include svg-sprite(search-icon);
    font-size: em(14);
    align-self: center;
    background-color: transparent;
    padding: 0;
    margin: 0;
    &:hover {
      background-color: transparent;
      @include svg-sprite(search-icon-hover);
    }
  }
  .button-clear {
    line-height: rem(18);
  }
  .button-close {
    display: none;
  }
  .visually-hidden {
    display: none;
  }
  .search-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: zindex(modal-overlay);
    box-sizing: border-box;
    width: 100%;
  }
}

.search-simple {
  &.search-active {
    .search-simple-container {
      display: block;
    }
  }
  form {
    display: flex;
    align-items: center;
    font-family: font-family(lato);
    font-weight: font-weight(lato-regular);
    background-color: color(white);
    border: .25px solid color(medium-gray);
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
    padding: 0 em(20);
    &.suggest-active {
      .button-clear {
        display: block;
      }
    }
  }
  input[type="text"] {
    flex-grow: 1;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  .term-completion {
    display: none;
    &.active {
      display: flex;
    }
  }
  .button-clear {
    display: none;
    color: color(medium-gray);
    border-right: 1px solid color(medium-gray);
    padding-right: em(20);
    margin-right: em(20);
  }
  .visually-hidden {
    display: none;
  }
}

.search-suggestions {
  background-color: color(white);
  transition: max-height transition(dropdown);
  box-sizing: border-box;
  overflow: auto;
  max-height: 0;
  &.active {
    max-height: calc(calc(100vh - 5.625rem) - 60px);
    @include respond-to(medium) {
      max-height: 75vh;
    }
  }
  > div {
    display: none;
    padding: em(10) em(20);
    &.active {
      display: block;
    }
  }
  h3 {
    font-size: font-size(h4);
    margin: 0 0 em(5);
  }
  .products {
    li {
      margin: 0 0 em(20);
    }
  }
  .mini-cart-product {
    display: flex;
    align-items: center;
    &:last-of-type {
      border-bottom: 1px dotted color(medium-light-gray);
      padding: 0 0 em(10) 0;
      margin: 0 0 em(20) 0;
    }
  }
  .mini-cart-image {
    min-width: auto;
    width: 25%;
    padding-right: em(20);
  }
  .mini-cart-name {
    margin: 0;
  }
  .term-completion {
    font-size: font-size(label);
    font-style: italic;
    .completed {
      color: color(medium-gray);
      padding-left: 1px;
    }
  }
  .term-label {
    padding-right: 2px;
  }
  .corrected {
    .original {
      display: none;
    }
  }
  .completed {
    .original {
      display: inline;
    }
  }
  .categories {
    a {
      padding: em(5) 0;
    }
  }
}

.search-for {
  display: flex;
  align-items: center;
  .icon-search {
    @include svg-sprite(search-icon);
    font-size: em(12);
    margin-right: em(5);
  }
}
