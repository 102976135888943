%button {
  display: inline-block;
  line-height: 1em;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  padding: em(5) em(20);
  margin: 0 0 em(10) 0;
  &:focus {
    outline: none;
  }
  @include respond-to(small) {
  width: auto;
  }
}

%button-primary {
  font-family: font-family(korolev);
  font-weight: font-weight(korolev-bold);
  font-size: font-size(label);
  text-transform: uppercase;
  color: color(white);
  background-color: color(black);
  height: rem(52);
  min-width: 205px;
  border: 1px solid color(black);
  letter-spacing: .025;
  &:hover {
    color: color(black);
    background: color(white);
  }
  &[disabled] {
    color: color(gray);
    background-color: color(extra-light-gray);
    cursor: not-allowed;
    &:hover {
      color: color(gray);
      background-color: color(extra-light-gray);
    }
  }
}

%button-secondary {
  font-family: font-family(korolev);
  font-weight: font-weight(korolev-medium);
  font-size: font-size(h2, true);
  color: color(white);
  background: color(black);
  text-transform: uppercase;
  height: rem(52);
  &:hover {
    color: color(white);
    background: color(dark-blue);
  }
  &[disabled] {
    color: color(gray);
    background-color: color(extra-light-gray);
    cursor: not-allowed;
    &:hover {
      color: color(gray);
      background-color: color(extra-light-gray);
    }
  }
}

%button-submit {
  font-family: font-family(lato);
  font-weight: font-weight(lato-heavy);
  font-size: font-size(h4, true);
  color: color(white);
  text-transform: uppercase;
  background-color: color(black);
  height: rem(42);
  &:hover {
    color: color(white);
    background-color: color(medium-dark-gray);
  }
  &[disabled] {
    color: color(gray);
    background-color: color(extra-light-gray);
    cursor: not-allowed;
    &:hover {
      color: color(gray);
      background-color: color(extra-light-gray);
    }
  }
}
%button-text {
  font-family: font-family(lato);
  font-weight: font-weight(lato-regular);
  font-size: font-size(p);
  color: color(light-blue);
  line-height: 1em;
  text-align: inherit;
  text-decoration: none;
  text-transform: none;
  background: transparent;
  width: auto;
  height: auto;
  padding: inherit;
  margin: inherit;
  &:hover {
    color: color(dark-blue);
    background-color: transparent;
  }
  &:focus {
    color: color(light-blue);
  }
}

button {
  @extend %button;
}

.button-primary {
  @extend %button;
  @extend %button-primary;
}

.button-secondary {
  @extend %button;
  @extend %button-secondary;
}

.button-submit,
.print-page {
  @extend %button;
  @extend %button-submit;
}
.button-text,
.button-text[type="submit"],
.cancel-button[type="submit"] {
  @extend %button-text;
}
.button-filter {
  &:after {
    @include svg-sprite(add-icon-white);
    font-size: em(20);
    content: '';
    margin-left: em(10);
  }
}

.button-close {
  @include svg-sprite(close-icon-blue);
  background-color: transparent;
  padding: 5px;
  margin: 0;
  &:hover {
    @include svg-sprite(close-icon);
  }
  &:focus {
    @include svg-sprite(close-icon-blue);
  }
}
