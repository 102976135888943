body {
  display: flex;
  flex-direction: column;
  background-color: color(extra-light-gray);
  height: 100vh;
}
main, #main {
  display: block;
  background-color: color(white);
  transition: transform transition(dropdown);
  flex: 1 0 auto;
  width: 100%;
  padding: em(20) 0 0 0;
  .container {
    padding: 0 rem(20);
  }
}
.container {
  box-sizing: border-box;
  max-width: 90%;
  margin: 0 auto;
}

section {
  display: block;
  .container {
    padding: 0 em(20) em(30) em(20);
  }
}
.informational {
  .container {
    padding: em(30) em(20);
    @include respond-to(medium) {
      padding: em(30) em(100);
    }
    @include respond-to(large) {
      padding: em(30) em(150);
    }
  }
}
.container-flex {
  @include respond-to(medium) {
    display: flex;
    flex-wrap: wrap;
  }
  .nav-secondary {
    padding: 0 0 em(40) 0;
    @include respond-to(medium) {
      box-sizing: border-box;
      width: 20%;
      max-width: em(280);
      padding-left: em(20);
    }
  }
  .login-secondary {
    width: 100%;
    padding: 0 0 em(30) 0;
  }
  .support-secondary {
    @include respond-to(medium) {
      padding: 0 em(30) 0 0;
    }
  }
  .primary-content {
    padding: 0 0 em(40) 0;
    @include respond-to(medium) {
      width: 80%;
      padding: 0;
    }
  }
  .primary-login {
    width: 100%;
    padding: 0 0 em(30) 0;
  }
  .primary-content-pdp {
    width: 100%;
  }
  .primary-support {
    @include respond-to(medium) {
      width: 70%;
    }
    @include respond-to(large) {
      width: 60%;
    }
    @include respond-to(xlarge) {
      width: 50%;
    }
  }
  .non-authenticated-support {
    margin: 0 auto;
  }
  .copy-content {
    padding: 0 0 em(40) 0;
    @include respond-to(medium) {
      width: 75%;
      margin: 0 auto;
    }
  }
  .primary-checkout {
    @include respond-to(medium) {
      padding: 0 0 em(40) 0;
    }
  }
  .primary-order-summary {
    margin: 0 auto;
    @include respond-to(medium) {
      padding: 0 0 em(40) 0;
    }
  }
  .visually-hidden {
    display: none;
  }
}
.container-flex-reverse {
  flex-direction: row-reverse;
  .nav-secondary {
    @include respond-to(medium) {
      padding: 0 em(20) em(40) 0;
    }
  }
}
.container-flex-center {
  @include respond-to(medium-large) {
    justify-content: center;
  }
}
.banner {
  width: 100%;
  margin: 0 0 em(20) 0;
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: em(10);
    @include respond-to(medium) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: em(20);
    }
  }
  .banner-image {
    background-image: url('../../images/banner-default.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    height: rem(155);
    margin: 0 0 em(20) 0;
    @include respond-to(medium) {
      height: rem(205);
    }
  }
  .banner-inline {
    width: 100%;
  }
  h2 {
    font-family: font-family(korolev);
    font-weight: font-weight(korolev-bold);
    font-size: font-size(h2);
    color: color(white);
    text-align: center;
    width: 100%;
    margin: 0;
    @include respond-to(medium) {
      font-size: font-size(h1-mobile);
      width: auto;
      margin-left: auto;
    }
  }
  i {
    display: inline-block;
    font-family: font-family(lato);
    font-weight: font-weight(lato-regular);
    font-style: italic;
    font-size: rem(20);
    line-height: 1em;
    vertical-align: rem(3);
    @include respond-to(medium) {
      font-size: font-size(h2, true);
    }
  }
  img {
    width: em(60);
    margin: 0 0 em(5) 0;
    @include respond-to(medium) {
      width: em(100);
      margin: 0;
    }
  }
  .button-submit {
    display: block;
    width: 90vw;
    margin: 0 auto;
    @include respond-to(medium) {
      display: none;
    }
  }
}
.banner-full {
  text-align: center;
  .container {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  .banner-image {
    height: rem(180);
    padding: rem(20);
    @include respond-to(medium) {
      height: rem(285);
      padding: rem(40);
    }
  }
  h1 {
    margin: 0;
  }
  h2 {
    font-size: font-size(h2);
    @include respond-to(medium) {
      font-size: font-size(h1);
      width: 100%;
      margin-left: 0;
    }
  }
  i {
    display: block;
    font-size: font-size(h2-mobile, true);
    @include respond-to(medium) {
      font-size: font-size(h2, true);
    }
  }
}
.banner-center {
  .container {
    justify-content: center;
  }
  h2 {
    margin: 0;
  }
}
img,
object[type="image/png"] {
  border: none;
  width: 100%;
  height: auto;
}

// Added Styles
a {
  color: #005ea6;
}

.breadcrumb-element {
  color: color(black);
  font-size: em(12);
}

.homepage-banner {
  margin: em(50) auto;
  max-width: em(600);
}

.logo-unauthenticated {
  img {
  margin-left: em(60);
  }
}

.logo-mobile.logo-unauthenticated {
  @media only screen and (min-width: 900px) {
    display: none;
  }
  img {
    margin-left: 135px;
    max-width: 50%;
    padding: em(10) 0;

    @media only screen and (min-width: 414px) {
      margin-left: 150px;
    }

    @media only screen and (min-width: 480px) {
      margin-left: 175px;
    }

    @media only screen and (min-width: 568px) {
      margin-left: 200px;
    }

    @media only screen and (min-width: 667px) {
      margin-left: 225px;
    }

    @media only screen and (min-width: 736px) {
      margin-left: 250px;
    }

    @media only screen and (min-width: 768px) {
      margin-left: 275px;
    }
  }
}

.menu-user {
  a:hover {
    color: color(medium-gray) !important;
  }
}

.mini-cart,
.mini-cart-total {
  @media only screen and (min-width: 900px) { 
    margin-left: em(30);
  }
}

.footer-quicklinks-asset {
  a {
    font-size: em(16);
  }

  a:hover {
    color: color(medium-gray);
  }
}

.nav-brands-visual.nav-brands-visual-new {
  padding: 0;
  margin: 0;

  .container.container-new {
    padding: 0;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;

    a {
      padding: 0;
      margin: 0;
      flex: 1 0 50%;
      display: block;
      box-shadow: 0 0 0 1px #7a7a7a;

      @media only screen and (min-width: 768px) {
        flex: 1 0 20%;
      }

      .flex-cell.nav-brand {
        padding: 0;
        margin: 0;
        height: 220px;
        width: 100%;
        background-color: color(black);

        &:hover {
          background-color: color(gray);
        }
        
        .nav-brand-img-new {
          width: auto;
          max-width: 44%;
          max-height: 55%;
          margin: 0;
          padding: 0;
          pointer-events: none;
        }
      }
    }
  }
}

.nav-brands-visual.nav-category-visual.nav-category-visual-new {
  margin: em(20) 0;
  padding: em(10) 0;

  @media only screen and (min-width: 900px) {
    margin: 0;
  }

  .container.container-new {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: em(10) 0;
    margin: 0 auto;

    @media only screen and (min-width: 900px) {
      flex-direction: row;
      justify-content: center;
      padding: 0 em(10);
    }

    a {
      flex: 1 0 auto;
      margin: em(10) 0;

      @media only screen and (min-width: 900px) {
        flex: 1 0 25%;
        margin: em(5) 0;
      }

      .new-subnav {
        .flex-cell.nav-brand.new-subnav__grid {
          background:#e1e1e1;
          margin: 0 em(5);

          &:hover {
            background: color(black);

           .new-subnav__text {
             color: color(white);
           } 
          }
        }

        &__text {
          text-transform: uppercase;
          font-size: em(24);
          font-weight: 600;
          line-height: 1.5;
        }
      }
    }
  }
}

.nav-main.nav-main-new.nav-authenticated {
    border: none;
}

.nav-main.nav-main-new {
  @media only screen and (min-width: 900px) {
    background: color(white);
    height: 30px;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  @media only screen and (min-width: 900px) {
    border-bottom: 1px solid #eee;
  }

  @media only screen and (min-width: 1024px) {
    height: 75px;
  }

  .container.container-new {
    @media only screen and (min-width: 900px) {
      padding: 0;
      margin: 0;
      min-width: 900px;
      height: 30px;
    }

    @media only screen and (min-width: 1024px) {
      height: 75px;
      min-width: 1024px;
    }

    @media only screen and (min-width: 1200px) {
      min-width: 1200px;
    }
    
    .nav-brands.nav-brands-img {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0;
      margin: 0;

      @media only screen and (max-width: 899px) {
        display: none;
      }

      @media only screen and (min-width: 900px) {
        a {
          flex: 1 0 10%;
          border-right: 1px solid #eee;
          padding: 0;
          margin: 0;
          height: 30px;
          min-width: 0;
          background-color: color(black);

          &:hover,
          &:active,
          &:focus {
            background-color: color(black);
          }

          img {
            height: 20px;
            width: 100%;
            margin: 0;
            padding-top: em(5);

            @media only screen and (min-width: 1024px) {
              height: 55px;
              padding-top: em(10);
            }
          }
        }
      }

      @media only screen and (min-width: 1024px) {
        height: 75px;

        a {
          height: 75px;
        }
      }
    }

    .nav-brands.nav-brands-img .cat-focus {
      background-color: color(white);
    }
  }
}

.menu-category-links .new-nav-links {
  @media only screen and (min-width: 900px) {
    display: none;
  }
}
